import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { showAllTooltips } from '../../utils/chartPlugin';

showAllTooltips();

const Wrapper = styled.div``;

function VoteSummary(props) {
  const {
    visible,
    title,
    data,
    total,
  } = props;

  const renderBar = useMemo(() => {
    if (!data) return null;

    const res = {
      labels: [],
      datasets: [
        {
          label: title,
          data: [],
          backgroundColor: '#96f1f8',
          hoverBackgroundColor: '#96f1f8',
        },
      ],
    };

    data.forEach((item) => {
      res.labels.push(item.option);
      res.datasets[0].data.push(item.count);
    });

    return (
      <>
        <HorizontalBar
          data={res}
          height={400}
          options={{
            legend: {
              display: false,
            },
            showAllTooltips: true,
            maintainAspectRatio: false,
            responsive: true,
            tooltips: {
              mode: 'nearest',
              position: 'average',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              footerAlign: 'center',
              bodyFontColor: '#000000',
              displayColors: false,
              callbacks: {
                title() {
                  return '';
                },
                label(tooltipItem) {
                  return (`${Math.round((tooltipItem.value / total) * 100)} %`);
                },
              },
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    min: 0,
                    fontColor: '#fbfafa',
                  },
                  gridLines: {
                    color: '#fbfafa',
                    lineWidth: 1,
                    zeroLineColor: '#fbfafa',
                    zeroLineWidth: 0,
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  gridLines: {
                    color: '#fbfafa',
                    lineWidth: 1,
                    zeroLineColor: '#fbfafa',
                    zeroLineWidth: 0,
                  },
                },
              ],
            },
          }}
        />
      </>
    );
  }, [data, title, total]);

  if (!visible) return null;

  return <Wrapper>{renderBar}</Wrapper>;
}

VoteSummary.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      option: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
  total: PropTypes.number,
};

VoteSummary.defaultProps = {
  visible: true,
  title: '',
  data: {},
  total: 0,
};

export default VoteSummary;
