/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import useClipboard from 'react-use-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { H4 } from '../Title/H';
import IconFacebook from '../../../assets/icons/votedshare-FB-default.png';
import IconFacebookHover from '../../../assets/icons/votedshare-FB-hover.png';
import IconLine from '../../../assets/icons/votedshare-LINE-default.png';
import IconLineHover from '../../../assets/icons/votedshare-LINE-hover.png';
import IconLink from '../../../assets/icons/votedshare-link-default.png';
import IconLinkHover from '../../../assets/icons/votedshare-link-hover.png';
import { BREAK_POINT_MOBILE } from '../../../assets/layout';
import usePostGoogleForm from '../../../hooks/usePostGoogleForm';

const Wrapper = styled.div``;

const Title = styled(H4)`
  margin-bottom: 1rem;
`;

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ShareIcon = styled.a`
  height: 2.4rem;
  width: 2.4rem;
  background-size: 100%;
  background-repeat: no-repeat;
  margin-right: 2.4rem;
`;

const ShareFB = styled(ShareIcon)`
  background-image: url(${IconFacebook});
  &:hover {
    background-image: url(${IconFacebookHover});
  }
`;

const ShareLine = styled(ShareIcon)`
  background-image: url(${IconLine});
  &:hover {
    background-image: url(${IconLineHover});
  }
`;

const ShareText = styled.div`
  position: absolute;
  font-size: 12px;
  bottom: 0;
  margin-bottom: -20px;
  margin-left: -4px;
`;

const ShareText1 = styled(ShareText)`
  margin-left: -6px;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-left: -1px;
  }
`;

const ShareText2 = styled(ShareText)`
  margin-left: -10px;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-left: -5px;
  }
`;

const ShareLink = styled.button`
  border: 0;
  height: 2.4rem;
  width: 2.4rem;
  cursor: pointer;
  background-color: transparent;
  background-size: contain;
  background-image: url(${IconLink});
  &:hover {
    background-image: url(${IconLinkHover});
  }
  &:focus {
    outline: none;
  }
`;

function SocialShareVote(props) {
  const { linkTo, text, voteShareActionURL, voteShareEntry } = props;

  const [, , , sendFromRequest] = usePostGoogleForm();
  const notify = () => toast('網址已複製！');

  const facebookShareLink = `https://www.facebook.com/sharer/sharer.php?u=${linkTo}&quote=${text}`;
  const LineShareLink = `http://line.naver.jp/R/msg/text/?${text} %0D%0A ${linkTo}`;
  const copyShareLink = `${text} \n${linkTo}`;

  const [isCopied, setCopied] = useClipboard(copyShareLink, {
    successDuration: 1000,
  });
  useEffect(() => {
    if (isCopied) {
      notify();
    }
  }, [isCopied]);

  const handleFBShare = () => {
    const payload = [
      {
        entry: voteShareEntry,
        value: '分享臉書',
      },
    ];
    sendFromRequest(voteShareActionURL, payload);
  };

  const handleLineShare = () => {
    const payload = [
      {
        entry: voteShareEntry,
        value: '分享Line',
      },
    ];
    sendFromRequest(voteShareActionURL, payload);
  };

  const handleCopyShare = () => {
    const payload = [
      {
        entry: voteShareEntry,
        value: '複製網址',
      },
    ];
    setCopied();
    sendFromRequest(voteShareActionURL, payload);
  };

  return (
    <Wrapper>
      <Title>邀請更多人參與</Title>
      <ItemWrapper>
        <ShareFB
          target="_blank"
          rel="noreferrer"
          href={facebookShareLink}
          onClick={handleFBShare}
        >
          <ShareText>分享臉書</ShareText>
        </ShareFB>
        <ShareLine
          target="_blank"
          rel="noreferrer"
          href={LineShareLink}
          onClick={handleLineShare}
        >
          <ShareText1>分享 Line</ShareText1>
        </ShareLine>
        <ShareLink onClick={handleCopyShare}>
          <ShareText2>複製網址</ShareText2>
        </ShareLink>
      </ItemWrapper>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
      />
    </Wrapper>
  );
}

SocialShareVote.propTypes = {
  linkTo: PropTypes.string,
  text: PropTypes.string,
  voteShareActionURL: PropTypes.string,
  voteShareEntry: PropTypes.string,
};

SocialShareVote.defaultProps = {
  linkTo: '',
  text: '',
  voteShareActionURL: '',
  voteShareEntry: '',
};

export default SocialShareVote;
