/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import background from '../../assets/images/banner_vote.png';
import backgroundMobile from '../../assets/images/banner_vote_mobile.png';
import backgroundTablet from '../../assets/images/banner_vote_tablet.png';
import { BREAK_POINT_MOBILE, BREAK_POINT_TABLET } from '../../assets/layout';
import { H2 } from '../Universal/Title/H';

const Wrapper = styled.div`
  padding: 0rem 2.5rem;
  background-image: url(${backgroundMobile});
  background-position: center top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border: #fbfafa solid 1px;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    background-image: url(${backgroundTablet});
  }
  @media only screen and (min-width: ${BREAK_POINT_TABLET}) {
    background-image: url(${background});
    padding: 0;
  }
`;

const Content = styled.div`
  margin-top: 4.5rem;
  width: 100%;
  padding-top: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    margin-top: 8.4rem;
  }
`;

const Depiction = styled.p`
  max-width: 45rem;
  margin-top: 1rem;
  text-align: justify;
  text-justify: inter-ideograph;
`;

function InfographicBanner() {
  return (
    <Wrapper>
      <Content>
        <H2>
          沒有人是局外人！
          <br />
          少子女化議題需要你我關注
        </H2>
        <Depiction>少子女化議題並非單純的生與不生，現實中有重重阻礙，需要你我一起累積群眾智慧、尋找更好的解方。邀請你參與投票、選出最關心的議題，你的寶貴意見將是未來推進政策、影響企業的重要參考，讓我們一同開啟更多改變的可能！</Depiction>
      </Content>
    </Wrapper>
  );
}

export default InfographicBanner;
