/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import shortid from 'shortid';
// import { isIOS } from 'react-device-detect';
import findIndex from 'lodash/findIndex';
import useGoogleSheet from '../../hooks/useGoogleSheet';
import usePostGoogleForm from '../../hooks/usePostGoogleForm';
import VoteOption from './VoteOption';
import VoteSummary from './VoteSummary';
import LoadingWithText from '../Universal/Loading/LoadingWithText';
import StatusWrapper from '../Universal/StatusWrapper';
import VoteTitle from './VoteTitle';
import SocialShareVote from '../Universal/SocialShare/SocialShareVote';

const Wrapper = styled.div`
  border: 1.5rem solid transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
  text-align: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const SubmitBtn = styled.button`
  padding: 1rem 3rem;
  border-radius: 4px;
  border: solid 1px #50d9e3;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  cursor: pointer;
`;

function VoteContainer(props) {
  const {
    sheetID,
    voteShareActionURL,
    voteShareEntry,
    titleBackground,
    titleBackgroundMobile,
    titleDepiction,
    mutiSelect,
    shareLink,
    shareText,
  } = props;
  const [
    formRes,
    formLoading,
    formError,
    sendFromRequest,
  ] = usePostGoogleForm();
  const [optionsData, optionsLoading, optionsError] = useGoogleSheet(
    'dataMode',
    sheetID,
    'options'
  );
  const [
    responseData,
    responseLoading,
    responseError,
    fetchResponse,
  ] = useGoogleSheet('dataMode', sheetID, '表單回應 1');
  const [configData, configLoading, configError] = useGoogleSheet(
    'configMode',
    sheetID,
    'setting'
  );
  const [selectOption, setSelectOption] = useState(null);
  const [lock, setLock] = useState(false);
  const [showSummary, setShowSummary] = useState(false);

  const isLoading = () => {
    if (configLoading || optionsLoading || responseLoading) return true;
    return false;
  };

  const isDone = useCallback(() => {
    if (formRes && selectOption && lock) return true;
    return false;
  }, [formRes, selectOption, lock]);

  const renderTitle = () => {
    if (!configData) return null;
    const input = {
      title: configData.title,
      background: titleBackground,
      backgroundMobile: titleBackgroundMobile,
      depiction: titleDepiction,
    };
    return <VoteTitle data={input} />;
  };

  const handleSubmit = () => {
    if (selectOption && configData) {
      // eslint-disable-next-line camelcase
      const { action_url, entry } = configData;
      const payload = [];

      if (mutiSelect) {
        const selectArr = selectOption.split(',');
        selectArr.forEach((sel) => {
          payload.push({
            entry,
            value: sel,
          });
        });
      } else {
        payload.push({
          entry,
          value: selectOption,
        });
      }
      sendFromRequest(action_url, payload, true);
      setLock(true);
    }
  };

  useEffect(() => {
    if (isDone()) {
      fetchResponse();
      setShowSummary(true);
    }
  }, [isDone, fetchResponse]);

  const handleSelectOption = useCallback(
    (val) => {
      if (!lock) {
        if (mutiSelect) {
          if (!selectOption) {
            setSelectOption(
              selectOption === val ? null : val
            );
          } else {
            const i = selectOption.indexOf(val);
            if (i >= 0) {
              setSelectOption(
                selectOption.replace(
                  i === 0 ? val : `,${val}`,
                  ''
                )
              );
            } else {
              setSelectOption(`${selectOption},${val}`);
            }
          }
        } else {
          setSelectOption(
            selectOption === val ? null : val
          );
        }
      }
    },
    [selectOption, setSelectOption, mutiSelect, lock]
  );

  const renderOptions = useMemo(() => {
    if (!optionsData) return null;
    return optionsData.map((item) => {
      let isSelect = false;
      if (selectOption) {
        const selectArr = selectOption.split(',');
        selectArr.forEach((sel) => {
          if (sel === item.option) {
            isSelect = true;
          }
        });
      }
      return (
        <VoteOption
          key={shortid.generate()}
          selectd={isSelect}
          value={item.option}
          onClick={handleSelectOption}
        />
      );
    });
  }, [optionsData, selectOption, handleSelectOption]);

  const renderSummary = () => {
    if (!responseData || !optionsData || !configData) return null;
    if (!showSummary) return null;

    const summary = optionsData.map((item) => {
      const t = item;
      t.count = 0;
      return t;
    });

    responseData.forEach((response) => {
      const res = response.answer.replace(/\s/g, '');
      const answerArr = res.split(',');
      answerArr.forEach((a) => {
        const i = findIndex(summary, ['option', a]);
        summary[i].count += 1;
      });
    });

    return (
      <>
        <VoteSummary
          title={configData.title}
          data={summary}
          total={responseData.length}
        />
        <SocialShareVote
          voteShareActionURL={voteShareActionURL}
          voteShareEntry={voteShareEntry}
          linkTo={shareLink}
          text={shareText}
        />
      </>
    );
  };

  const renderSendLoading = () => {
    if (!formLoading) return null;
    return <LoadingWithText text="上傳中 ....." />;
  };

  const renderLoading = () => {
    if (!isLoading()) return null;
    return (
      <LoadingWrapper>
        <LoadingWithText />
      </LoadingWrapper>
    );
  };

  const renderError = () => {
    if (!configError && !optionsError && !responseError) return null;

    return (
      <StatusWrapper>
        <div>{configError || optionsError ? '載入選項時發生錯誤' : ''}</div>
        <div>{responseError ? '傳送答案時發生錯誤' : ''}</div>
      </StatusWrapper>
    );
  };

  const renderSendError = () => {
    if (!formError) return null;

    // const iosMsg = 'iOS 14 的用戶很抱歉，目前我們正在更新 iOS 14 最新版軟體升級後的投票功能，將於 5 月中完成，誠摯邀請您屆時再次來訪、投票。';
    const errMsg = '暫時無法傳送答案';
    return <StatusWrapper>{errMsg}</StatusWrapper>;
  };

  const renderSubmitBtn = () => {
    if (!responseData || !optionsData || !configData) return null;
    if (formLoading || showSummary) return null;
    return <SubmitBtn onClick={handleSubmit}>投票</SubmitBtn>;
  };

  return (
    <>
      <Wrapper>
        {renderTitle()}
        <OptionContainer>{renderOptions}</OptionContainer>
        {renderLoading()}
        {renderSendLoading()}
        {renderSendError()}
        {renderError()}
        {renderSubmitBtn()}
        {renderSummary()}
      </Wrapper>
    </>
  );
}

VoteContainer.propTypes = {
  sheetID: PropTypes.string,
  voteShareActionURL: PropTypes.string,
  voteShareEntry: PropTypes.string,
  titleBackground: PropTypes.string,
  titleBackgroundMobile: PropTypes.string,
  titleDepiction: PropTypes.string,
  mutiSelect: PropTypes.bool,
  shareLink: PropTypes.string,
  shareText: PropTypes.string,
};

VoteContainer.defaultProps = {
  sheetID: '',
  voteShareActionURL: '',
  voteShareEntry: '',
  titleBackground: '',
  titleBackgroundMobile: '',
  titleDepiction: '',
  mutiSelect: false,
  shareLink: '',
  shareText: '',
};

export default VoteContainer;
