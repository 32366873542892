/* eslint-disable object-curly-newline */
import React from 'react';
import styled from 'styled-components';
import { H3, H4 } from '../Universal/Title/H';
import infographicDataTitleType from '../../propTypes/infographicDataTitleType';
import { useViewport } from '../../hooks/useViewport';

const Title = styled(H3)`
  width: 100%;
  text-align: center;
`;

const TitleMobile = styled(H4)`
  width: 100%;
  text-align: center;
`;

const TitleWrapper = styled.div`
  margin-top: 6.4rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => (`url(${props.background})` || '')};
`;

const TitleWrapperMobile = styled.div`
  height: 7.5rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(props) => (`url(${props.background})` || '')};
`;

const Depiction = styled.div`
  font-weight: bold;
`;

function VoteTitle({ data }) {
  const { background, backgroundMobile, title, depiction } = data;
  const [viewport] = useViewport();

  if (backgroundMobile !== '' && (viewport === 'mobile' || viewport === 'tablet')) {
    return (
      <TitleWrapperMobile background={backgroundMobile}>
        <TitleMobile>
          {title}
          {depiction}
        </TitleMobile>
      </TitleWrapperMobile>
    );
  }

  return (
    <TitleWrapper background={background}>
      <Title>{title}</Title>
      <Depiction>{depiction}</Depiction>
    </TitleWrapper>
  );
}

VoteTitle.propTypes = {
  data: infographicDataTitleType.shape,
};

VoteTitle.defaultProps = {
  data: infographicDataTitleType.preset,
};

export default VoteTitle;
