import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import LoadingIcon from '../../../assets/icons/loading.svg';
import StatusWrapper from '../StatusWrapper';

// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingWrapper = styled.div`
  display: inline-block;
  animation: ${rotate} 2s linear infinite;
  width: 30px;
  height: 30px;
  opacity: 0.6;
`;

const Text = styled.div`
  font-size: 0.8rem;
  margin-top: 0.8rem;
`;

function LoadingWithText({ text, className }) {
  const renderText = () => {
    if (text === '' || !text) return null;
    return (
      <Text>{text}</Text>
    );
  };

  return (
    <StatusWrapper className={className}>
      <LoadingWrapper>
        <img src={LoadingIcon} alt="loading" />
      </LoadingWrapper>
      {renderText()}
    </StatusWrapper>
  );
}

LoadingWithText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

LoadingWithText.defaultProps = {
  className: '',
  text: '',
};

export default LoadingWithText;
