/* eslint-disable no-param-reassign */
import { Chart } from 'react-chartjs-2';

export const showAllTooltips = () => {
  Chart.pluginService.register({
    beforeRender(chart) {
      if (chart.config.options.showAllTooltips) {
        // create an array of tooltips
        // we can't use the chart tooltip because there is only one tooltip per chart
        chart.pluginTooltips = [];
        chart.config.data.datasets.forEach((dataset, i) => {
          chart.getDatasetMeta(i).data.forEach((sector) => {
            chart.pluginTooltips.push(
              new Chart.Tooltip(
                {
                  _chart: chart.chart,
                  _chartInstance: chart,
                  _data: chart.data,
                  _options: chart.options.tooltips,
                  _active: [sector],
                },
                chart,
              ),
            );
          });
        });

        // turn off normal tooltips
        chart.options.tooltips.enabled = false;
      }
    },
    afterDraw(chart, easing) {
      if (chart.config.options.showAllTooltips) {
        if (!chart.allTooltipsOnce) {
          if (easing !== 1) return;
          chart.allTooltipsOnce = true;
        }
        // turn on tooltips
        chart.options.tooltips.enabled = true;
        Chart.helpers.each(chart.pluginTooltips, (tooltip) => {
          tooltip.initialize();
          tooltip.update();
          // we don't actually need this since we are not animating tooltips
          tooltip.pivot();
          tooltip.transition(easing).draw();
        });
        chart.options.tooltips.enabled = false;
      }
    },
  });
};

export default Chart;
