import React from 'react';
import styled from 'styled-components';
import MainLayout from '../layout/MainLayout';
import SEO from '../components/Universal/SEO';
import MainContainer from '../components/Universal/Container/MainContainer';
import Vote from '../components/Vote/Vote';
import VoteBanner from '../components/Vote/VoteBanner';
import Contact from '../components/Contact/Contact';
import env from '../env';
import VoteBackground1 from '../assets/icons/bar04_1.png';
import VoteBackground1M from '../assets/icons/bar04_1_mobile.png';
import VoteBackground2 from '../assets/icons/bar04_2.png';
import VoteBackground2M from '../assets/icons/bar04_2_mobile.png';
import VoteBackground3 from '../assets/icons/bar04_3.png';
import VoteBackground3M from '../assets/icons/bar04_3_mobile.png';

const WrapperContainer = styled(MainContainer)`
  padding-top: 4rem;
  padding-bottom: 10rem;
`;

const DESC = '永齡基金會與社企流攜手，打造明日戶政所，提供少子女化議題現況、彙整政府資源與國內外創新行動。邀請你參與投票、選出最關心的議題，你的寶貴意見將是未來推進政策、影響企業的重要參考，讓我們一同開啟更多改變的可能！';

const Vote1ShareText = '哪一個婚育狀態最符合你的期待？ 一起到明日戶政所了解臺灣少子女化議題 ';
const Vote2ShareText = '臺灣少子女化議題中，你最關注哪些問題？ 一起到明日戶政所了解臺灣少子女化議題 ';
const Vote3ShareText = '哪些因素，會讓你提高生育意願？ 一起到明日戶政所了解臺灣少子女化議題 ';

const Vote1ShareLink = 'http://bit.ly/3hvVGpt';
const Vote2ShareLink = 'http://bit.ly/3rIOHhS';
const Vote3ShareLink = 'https://bit.ly/2Jy7xXN';

function VotePage() {
  return (
    <MainLayout>
      <SEO title="參與投票 | 明日戶政所——少子女化全解析" description={DESC} />
      <VoteBanner />
      <WrapperContainer>
        <Vote
          sheetID={env.FH_VOTE_1_SHEET_ID}
          voteShareActionURL={env.FH_VOTE_SHARE_1_ACTION_URL}
          voteShareEntry={env.FH_VOTE_SHARE_1_ENTRY}
          titleBackground={VoteBackground1}
          titleBackgroundMobile={VoteBackground1M}
          titleDepiction="（單選）"
          shareText={Vote1ShareText}
          shareLink={Vote1ShareLink}
        />
        <Vote
          sheetID={env.FH_VOTE_2_SHEET_ID}
          voteShareActionURL={env.FH_VOTE_SHARE_2_ACTION_URL}
          voteShareEntry={env.FH_VOTE_SHARE_2_ENTRY}
          titleBackground={VoteBackground2}
          titleBackgroundMobile={VoteBackground2M}
          titleDepiction="（複選）"
          mutiSelect
          shareText={Vote2ShareText}
          shareLink={Vote2ShareLink}
        />
        <Vote
          sheetID={env.FH_VOTE_3_SHEET_ID}
          voteShareActionURL={env.FH_VOTE_SHARE_3_ACTION_URL}
          voteShareEntry={env.FH_VOTE_SHARE_3_ENTRY}
          titleBackground={VoteBackground3}
          titleBackgroundMobile={VoteBackground3M}
          titleDepiction="（複選）"
          mutiSelect
          shareText={Vote3ShareText}
          shareLink={Vote3ShareLink}
        />
      </WrapperContainer>
      <Contact />
    </MainLayout>
  );
}

export default VotePage;
