import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0,0,0,0.4);
  padding: 1rem;
`;

function StatusWrapper({ className, children }) {
  return (
    <Wrapper className={className}>
      {children}
    </Wrapper>
  );
}

StatusWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

StatusWrapper.defaultProps = {
  className: '',
  children: null,
};

export default StatusWrapper;
