import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BREAK_POINT_MOBILE } from '../../assets/layout';

const Wrapper = styled.div`
  word-break: break-all;
  padding: 1rem;
  height: 120px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  letter-spacing: 0.11px;
  text-align: center;
  color: #404a5b;
  margin: 0rem 1rem;
  cursor: pointer;
  border: solid 1px #e0e4e7;
  border-radius: 4px;
  background-color: ${(props) => (props.selectd ? '#96f1f8' : '#FFFFFF')};
  margin-bottom: 2rem;

  width: 38%;
  @media only screen and (min-width: ${BREAK_POINT_MOBILE}) {
    width: 20%;
  }
`;

function VoteOption(props) {
  const { value, onClick, selectd } = props;
  return (
    <Wrapper selectd={selectd} onClick={() => { onClick(value); }}>
      {value}
    </Wrapper>
  );
}

VoteOption.propTypes = {
  selectd: PropTypes.bool,
  value: PropTypes.string,
  onClick: PropTypes.func,
};

VoteOption.defaultProps = {
  selectd: false,
  value: '',
  onClick: () => {},
};

export default VoteOption;
